<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{ label }}</span>
		</div>
		<el-input class="a-ml-20 a-flex-1" :class="large ? 's-large-input' : 's-nomarl-input'"
			:placeholder="placeholder" :value="value"
			:prefix-icon="prefixIcon == 'el-icon-search' ? 'el-icon-search' : ''" @input="handlerChange"
			:maxlength="maxlength">
			<template slot="prepend" v-if="prefixIcon != 'el-icon-search'">¥</template>
		</el-input>
	</div>

</template>

<script>
export default {
	props: {
		label: {
			type: String
		},
		value: {
			required: true,
			default: ''
		},
		placeholder: {
			type: String,
			default: '请输入'
		},
		prefixIcon: {
			type: String,
			default: "el-icon-search"
		},
		/**
		 * 是否必填
		 */
		notnull: {
			type: Boolean,
			default: false,
			required: false,
		},
		/**
		 * 是否为较长的输入框
		 */
		large: {
			type: Boolean,
			required: false,
			default: false
		},
		maxlength:{
			type:Number,
			default:50
		}
	},
	data() {
		return {
		}
	},
	methods: {
		handlerChange(e) {
			console.log('handlerChange', e)
			this.$emit('input', e)
		}
	}
}
</script>

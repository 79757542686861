<template>
	<el-breadcrumb class="app-breadcrumb" separator="/">
		<transition-group name="breadcrumb">
			<el-breadcrumb-item v-for="(item, index) in levelList" v-if="item.title" :key="item.path">
				<span v-if="item.redirect === 'noredirect' || index == levelList.length - 1" class="no-redirect">{{
						item.title
				}}</span>
				<a v-else @click.prevent="handleLink(item)">{{ item.title }}</a>
			</el-breadcrumb-item>
		</transition-group>
	</el-breadcrumb>
</template>

<script>
import {
	mapGetters,
	mapState,
	mapActions
} from "vuex";

import pathToRegexp from 'path-to-regexp'

export default {
	data() {
		return {
			levelList: null
		}
	},

	computed: {
		...mapState({
			routeHis: state => state.menu.routeHis
		}),
	},

	created() {
		this.getBreadcrumb();
	},

	methods: {
		getBreadcrumb() {
			if (this.routeHis.length <= 2) {
				this.levelList = this.routeHis.map(item => {
					return {
						title: item[1].meta.title,
						path: item[1].path,
						redirect: item[1].redirect
					}
				});
			} else {
				// this.levelList = this.routeHis.slice(this.routeHis.length-3,this.routeHis.length-1).map(item=>{
				// 	return {
				// 		title:item[1].meta.title,
				// 		path:item[1].path,
				// 		redirect:item[1].redirect
				// 	}
				// }).reverse();
				this.levelList = this.routeHis.slice(this.routeHis.length - 2).map(item => {
					return {
						title: item[1].meta.title,
						path: item[1].path,
						redirect: item[1].redirect
					}
				})
			}

			// console.log('getBreadcrumb levelList', this.levelList, this.routeHis)
		},
		pathCompile(path) {
			const {
				params
			} = this.$route;
			var toPath = pathToRegexp.compile(path);
			return toPath(params)
		},
		handleLink(item) {
			const {
				redirect,
				path
			} = item;
			if (redirect) {
				this.$router.push(redirect);
				return
			}
			this.$router.push(this.pathCompile(path))
		}
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
	display: inline-block;
	font-size: 14px;
	line-height: 50px;
	margin-left: 10px;

	.no-redirect {
		color: #97a8be;
		cursor: text;
	}
}
</style>

<template>
	<div class="a-flex-rfsfs">
		<div class="s-search-label a-flex-rfec a-mt-8" style="width: 82px" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<div>{{ label }}</div>
		</div>
		<div>
			<el-upload :show-file-list="false" :action="url" :headers="headers" :data="data" :on-preview="handlePreview"
				:on-remove="handleRemove" :before-remove="beforeRemove" :limit="limit" :on-exceed="handleExceed"
				:on-success="handleSuccess" :file-list="fileList" name="file" :on-change="handleChange"
				:accept="acceptVali" ref="upload">
				<!-- <i class="el-icon-plus"></i> -->
				<el-button style="border: 1px solid #DADDE0;border-radius: 4px;" icon="el-icon-upload2">上传文件</el-button>
				<slot name="tip">
					<!-- <div class="el-upload__tip a-mt-8 a-c-999 a-fs-14">
                        支持：{{ acceptStr }}格式
                    </div> -->
				</slot>
			</el-upload>
			<div class="a-mt-16 a-flex-rfsc" v-if="fileList.length">
				<div class="img-content" v-for="(item, index) in fileList" :key="index">
					<img :src="item.url" style="width:100px;height:100px;border-radius: 4px;" alt="">
					<i class="el-icon-error deleteImg" @click="beforeRemove(item, index)"></i>
				</div>
			</div>
			<div class="el-upload__tip a-c-999 a-fs-14">
				支持：{{ acceptStr }}格式
			</div>
		</div>

		<le-preview-img v-model="showPreviewImg" :imgList="previewList"></le-preview-img>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
		},
		value: {
			required: false,
			default: "",
		},
		notnull: {
			type: Boolean,
			default: false,
			required: false,
		},
		/**
		 * 是否为较长的输入框
		 */
		large: {
			type: Boolean,
			required: false,
			default: false,
		},
		acceptArr: {
			type: Array,
			default: () => [".png", ".jpg", ".jpeg"],
		},
		limit: {
			type: Number,
			default: 1,
		},
		uploadUrl: {
			type: String,
			default: ''
		}
	},
	computed: {
		acceptStr() {
			return this.acceptArr.join("，");
		},
		acceptVali() {
			return this.acceptArr.join(",");
		},
	},
	watch: {
		value: {
			handler(val) {
				if (val != "") {
					if (this.iswatch) {
						if (typeof val == "string") {
							let names = val.split("/");
							let showName = names[names.length - 1];
							if (!this.filesImg[showName]) {
								this.fileList.push({
									name: showName,
									url: this.filesImg[showName] || val,
								});
							}
						}
						this.iswatch = false;
					}
				}
			},
		},
		uploadUrl: {
			immediate: true,
			handler(val) {
				if (val) {
					this.url = this.$Config.axiosBaseUrl + val
				}
			}
		}
	},
	mounted() {
		// this.fileList.push({"name":"aaa.pj"});
		// this.$emit('input', this.fileList)
		// console.log("le-upload-file-img.vue：",this.value);
	},
	data() {
		return {
			url: this.$Config.axiosBaseUrl + this.$Config.apiUrl.uploadFile,
			headers: {
				token: "WVRNM05UYzFOR1JsWXpVeVlXVmtabVEzWkRNeVpEQXdPREppTmpZMU9XRT0=",
				appId: this.$Config.appId,
			},
			data: {
				key: "company/upload",
			},
			fileList: [],
			filesImg: {},
			iswatch: true,
			showPreviewImg: false, //是否预览
			previewList: [{}], //预览文件列表
		};
	},
	methods: {
		handlerChange(e) {
			console.log("handlerChange", e);
			this.$emit("input", e);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			this.showPreviewImg = true;
			this.previewList = [file.url];
		},
		handleExceed(files, fileList) {
			if (fileList.length > 0) {
				this.$message.warning('该文件已执行过导入操作')
				console.log('handleExceed',fileList)
				// if (fileList[0].response.code == 0) {
				// 	this.$message.warning(
				// 		`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length
				// 		} 个文件，共选择了 ${files.length + fileList.length} 个文件`
				// 	);
				// }
			}

		},
		beforeRemove(file, index) {
			let that = this;
			return this.$confirm(`确定移除 ${file.name}？`, {
				callback: (action) => {
					if (action == "confirm") {
						that.$emit("input", "");
						that.fileList.splice(index, 1);
					}
				},
			});
		},
		handleChange(file, fileList) {
			console.log("handleChange", file, fileList);
			if (file.response) {
				if (file.response.code == 0) {

					this.iswatch = false;
					this.filesImg[file.name] = file.url || "";
					// debugger
					this.fileList.push({
						name: file.name,
						url: file.response.data
					})
					this.$emit("input", file.response.data);
				} else {
					this.fileList = []
					this.filesImg = {}
				}

			}
		},
		handleSuccess(response, file, fileList) {
			let that = this
			console.log(this.fileList)
			if (response.code == 0) {
				this.$emit('handleSuccess')
			} else {
				this.fileList = []
				this.filesImg = {}
				this.$message.error(response.msg)


			}

		}
	},
};
</script>
<style lang="scss" scoped>
.el-upload__tip {
	white-space: nowrap;
}

.img-content {
	width: 100px;
	height: 100px;
	position: relative;
	margin-right: 14px;

	.deleteImg {
		font-size: 18px;
		position: absolute;
		top: -8px;
		right: -8px;
		color: #606366;
		background: #fff;
		border-radius: 50%
	}
}
</style>

import zjp from "@/config/api/zjp";

const brandEnv = 'yd'
export default {
	projectName: '',
	projectTitle: '',
	baseUrl: '',
	header: [{
		key: 'appId',
		value:  'zjp.loushui.web'
	}],
	tokenKey: 'JHDesignToken',
	timeout: 1000 * 60 * 60, // 1个小时
	defaultTimeout: 1000 * 60 * 5, // 5分钟

	axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'https://loushui.shanghai-wonder.com/' :
		'https://loushui.shanghai-wonder.com/', //


	apiUrl: {
		...zjp,
	},
};

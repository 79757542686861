import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";
import getMenuList from '@/utils/menu';

const initInfo = {
	id: '',
	mobile: '',
	avatarUrl: '',
	nickName: '',
	idCardNumber: '',
	idCardImagePath: '',
	idCardBackImagePath: '',
	realName: ''
}

const state = {
	user: {
		...initInfo
	}
}

const mutations = {
	setUserInfo: (state, userInfo) => {
		state.user = userInfo
	}
}

const actions = {

	getUserInfo({
		commit
	}) {
		return new Promise(async (resolve, reject) => {
			try {
				let arg = {
					url: Config.apiUrl.getUserInfo,
					params: {},
					showLoading:false,
				};
				const {
					result
				} = await _get(arg)

				if (result.code === 0) {
					const data = result.data;
				}
				resolve(result)
			} catch (err) {
				reject(err);
				Utils.removeToken()
				window.location.hash='#/login'
				
				
				
			}
		});
	},


	// 前端 登出
	logout({
		commit
	}){
		return new Promise(resolve => {
			Utils.removeToken();
			resolve()
		})
	}
}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}

<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{ label }}</span>
		</div>
		<el-select class="a-ml-20 a-flex-1" :class="large ? 's-large-input' : 's-nomarl-input'" :value="value"
			filterable clearable :multiple="multiple" :collapse-tags="collapse" remote :placeholder="placeholder"
			:disabled="readonly"
			:remote-method="remoteMethod" @change="handlerChange" @clear="remoteMethod('')">
			<!-- @blur="remoteMethod('')" -->
			<el-option v-for="item in myOptions" :key="item.value" :label="item.label" :value="item.value">
			</el-option>
		</el-select>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				required: true,
			},
			placeholder: {
				type: String,
				default: "请选择",
			},
			label: {
				type: String,
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false,
			},
			multiple: {
				type: Boolean,
				required: false,
				default: false
			},
			collapse: {
				type: Boolean,
				required: false,
				default: false
			},
			readonly:{
				type:Boolean,
				default:false
			},
			options: {
				type: Object,
				required: true,
				validator: (param) => {
					if (!param.url) {
						console.log("le-select-remote-search 参数缺少url");
						return false;
					}
					if (!param.method) {
						console.log("le-select-remote-search 参数缺少method(post|get)");
						return false;
					}
					if (!param.params) {
						console.log("le-select-remote-search 参数缺少params");
						return false;
					}
					if (!param.labelKey) {
						console.log("le-select-remote-search 参数缺少labelKey");
						return false;
					}
					if (!param.valueKey) {
						console.log("le-select-remote-search 参数缺少valueKey");
						return false;
					}
					if (!param.searchKey) {
						console.log("le-select-remote-search 参数缺少searchKey");
						return false;
					}
					return true;
				},
			},
		},
		// watch: {
		// 	value: {
		// 		// 子级下拉框的监听对象
		// 		deep: true,
		// 		handler: function(val) {
		// 			this.remoteMethod();
		// 		},
		// 		immediate: true
		// 	},
		// },
		data() {
			return {
				myOptions: [],
				selectLabel: '',
				myValue:''
			};
		},
		mounted() {
			this.remoteMethod();
		},

		methods: {
			handlerChange(e) {
				this.$emit("input", e);
			},
			remoteMethod(key) {
				let func = this.$Axios._post;
				if (this.options.method.toLowerCase() == "get") {
					func = this.$Axios._get;
				}
				let params = {
					...this.options.params,
					pageNum: 1,
					pageSize: 100000,
				};
				params[this.options.searchKey] = key;

				func({
					...this.options,
					params: params,
				}).then((res) => {

					this.myOptions = res.result.data.list.map((item) => {
						if (this.value == item[this.options.valueKey]) {
							this.selectLabel = item[this.options.labelKey]
							console.log('this.value', this.value, 'this.selectLabel', this.selectLabel)
						}
						return {
							label: item[this.options.labelKey],
							value: item[this.options.valueKey],
						};
					});
					// let that = this
					// this.$nextTick(()=>{
					// 	that.myValue = this.value
					// })
				});
			},
		},
	};
</script>

<template>
	<div class="a-flex-rfsc a-ptb-12"  >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{label}}</span>
		</div>
		<el-select class="a-ml-20 a-flex-1" :class="large?'s-large-input':'s-nomarl-input'" :value="value" filterable clearable :placeholder="placeholder" @change="handlerChange">
			<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
			</el-option>
		</el-select>
	</div>
	
</template>

<script>
	export default {
		props:{
			value:{
				required:true,
			},
			placeholder:{
				type:String,
				default:'请选择'
			},
			options:{
				type:Array,
				required:true
			},
			label:{
				type:String
			},
			/**
			 * 是否必填
			 */
			notnull:{
				type:Boolean,
				default:false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large:{
				type:Boolean,
				required:false,
				default:false
			}
		},
		data() {
			return {
				
			}
		},
		methods:{
			handlerChange(e){
				this.$emit('input',e)
			}
		}
	}
</script>

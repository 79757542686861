import Layout from '@/components/layout/Layout.vue';
const Login = resolve => require(['@/views/login/index'], resolve); //登入页


export default [
	{
		path: '/',
		name: 'login',
		component: () =>
			import('@/views/loushui/index'),
		meta: {
			title: '普查管理',
			icon: 'dashboard',
			keepAlive: false
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			requireAuth: true,
			hidden: true,
			title: '首页',
		}
	},
	{
		path: '/loushui',
		name: 'loushui',
		component: ()=>import('@/views/loushui/index'),
		meta: {
			title: '普查管理',
			icon: 'dashboard',
			keepAlive: false
		}
	},
	// {
	// 	path: '/',
	// 	name: 'home',
	// 	component: () =>
	// 		import('@/views/home/index'),
	// 	meta: {
	// 		requireAuth: false,
	// 		hidden: true,
	// 		title: '首页',
	// 	}
	// },
	{
		path: '*',
		redirect: '/',
		hidden: true
	},


]

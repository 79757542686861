<template>
	<div class="a-flex-rfsfs a-ptb-12" >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span class="a-mt-8">{{label}}</span>
		</div>
		<div class="a-flex-cfsfs a-ml-20">
			<el-cascader :class="large?'s-large-input':'s-nomarl-input'" placeholder="请选择省市区" :options="options" clearable
				v-model="selectedOptions" @change="handleChange">
			</el-cascader>
			<el-input class="a-mt-8" v-model="myAddress" :class="large?'s-large-input':'s-nomarl-input'" placeholder="请输入详细地址"
				@input="handlerDetailChange">
			</el-input>
		</div>

	</div>

</template>

<script>
	/**
	 * 省市区选择
	 */
	import {
		regionData,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	export default {
		props: {
			province: {
				required: false,
				type: String,
			},
			city: {
				required: false,
				type: String,
			},
			area: {
				required: false,
				type: String,
			},
			address: {
				required: false,
				type: String,
			},
			placeholder: {
				type: String,
				default: '请输入详细地址'
			},
			label: {
				type: String
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				options: regionData,
				selectedOptions: ["","",""],
				myAddress:'',
				provinceChange:false,
				cityChange:false,
				areaChange:false,
			}
		},
		computed:{
			codeChange(){
				return{
					provinceChange:this.provinceChange,
					cityChange:this.cityChange,
					areaChange:this.areaChange
				}
			}
		},
		watch:{
			province:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.provinceChange=true
					}
				}
			},
			city:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.cityChange=true
					}
				}
			},
			area:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.areaChange=true
					}
				}
			},
			codeChange:{
				handler(val){
					if(this.provinceChange && this.cityChange && this.areaChange){
						this.$set(this,'selectedOptions',[this.province,this.city,this.area])
						this.provinceChange = false
						this.cityChange = false
						this.areaChange = false
					}
				}
			},
			address:{
				handler(val,oldval){
					if(val!=oldval){
						this.myAddress=val
					}
					
				}
			}
		},
		mounted() {
		},
		methods: {

			handleChange(value) {
				this.$emit("update:province",value[0])
				this.$emit("update:city",value[1])
				this.$emit("update:area",value[2])
			},
			handlerDetailChange(value) {
				this.$emit("update:address",value)
			}
		}

	}
</script>

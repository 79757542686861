<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{ label }}</span>
		</div>

		<el-select ref="numSelect" class="a-ml-20 a-flex-1" :class="large ? 's-large-input' : 's-nomarl-input'"
			v-model="myValue" filterable clearable :placeholder="placeholder" @change="handlerChange">
			<el-option style="height: 40px;" v-for="item in myOptions" :key="item.minData + '-' + item.maxData"
				:label="item.minData + '-' + item.maxData + unit" :value="item.minData + '-' + item.maxData">
			</el-option>
			<div class="a-flex-cfsfs  a-line-t-e0 a-ptb-10 a-plr-15">
				<span class="a-c-666 a-fs-14">手动区间 ({{ unit }})</span>
				<div class="a-flex-rfsc a-mt-12">
					<input type="number" v-model="myMinData" min="0"
						style="width: 64px;border:1px solid #DADDE0 ;height: 28px;border-radius: 4px;" />
					<span class="a-ml-08">-</span>
					<input class="a-ml-08" type="number" v-model="myMaxData" min="0"
						style="width: 64px; border:1px solid #DADDE0 ;height: 28px; border-radius: 4px;" />
					<el-button class="a-ml-08" type="primary" @click="handlerAddOption">确定</el-button>
				</div>
			</div>
		</el-select>

	</div>

</template>

<script>
export default {
	props: {
		label: {
			type: String
		},
		minData: {
			required: true,
			default: ''
		},
		maxData: {
			required: true,
			default: ''
		},
		unit: {
			required: false,
			default: '%'
		},
		options: {
			type: Array,
			required: true
		},
		placeholder: {
			type: String,
			default: '请选择区间'
		},

		/**
		 * 是否必填
		 */
		notnull: {
			type: Boolean,
			default: false,
			required: false,
		},
		/**
		 * 是否为较长的输入框
		 */
		large: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	watch: {
		minData: {
			handler(val) {

				this.myValue = val ? this.minData + '-' + this.maxData : ""
			}
		},
		maxData: {
			handler(val) {
				this.myValue = val ? this.minData + '-' + this.maxData : ""
			}
		}
	},
	data() {
		return {
			myMinData: '',
			myMaxData: '',
			myOptions: JSON.parse(JSON.stringify(this.options)),
			myValue: ''
		}
	},
	methods: {
		handlerChange(e) {
			console.log('handlerChange', e)
			let _minData = e.split('-')[0]
			let _maxData = e.split('-')[1]
			this.$emit('update:minData', _minData)
			this.$emit('update:maxData', _maxData)

		},

		handlerAddOption() {

			this.$emit('update:minData', Number(this.myMinData))
			this.$emit('update:maxData', Number(this.myMaxData))
			if (this.myOptions[this.myOptions.length - 1].temp) {
				this.myOptions.splice(this.myOptions.length - 1, 1)
			}
			this.myOptions.push({
				minData: this.myMinData,
				maxData: this.myMaxData,
				temp: true
			})
			this.myValue = this.myMinData + '-' + this.myMaxData
			this.$refs['numSelect'].blur()
		},
		// clearRange() {
		// 	this.$set(this, 'myValue', '')
		// 	this.myValue = " "
		// }

	}
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.item.hidden)?_c('div',{staticClass:"menu-wrapper"},[(_vm.item.children.length > 1)?_c('el-submenu',{attrs:{"index":_vm.resolvePath(_vm.item.children[0].path)}},[_c('template',{slot:"title"},[_c('item',{attrs:{"title":_vm.item.name,"icon":_vm.$route.path.split('/')[1] != _vm.item.path.split('/')[1]
                            ? _vm.item.icon
                            : _vm.item.iconActive,"colorClass":_vm.$route.path.split('/')[1] !=
                        _vm.item.children[0].path.split('/')[1]
                            ? 'a-c-master'
                            : 'a-c-blue'}})],1),_vm._l((_vm.item.children),function(child){return _c('sidebar-item',{key:child.path,staticClass:"nest-menu",attrs:{"is-nest":true,"item":child,"base-path":_vm.resolvePath(child.path)}})})],2):_c('el-menu-item',{staticClass:"menuitemnobody",attrs:{"index":_vm.item.children.length ? _vm.item.children[0].path : _vm.item.path}},[_c('item',{attrs:{"title":_vm.item.name,"icon":_vm.$route.path.split('/')[1] != _vm.item.path.split('/')[1]
                        ? _vm.item.icon
                        : _vm.item.iconActive,"colorClass":_vm.$route.path.split('/')[1] != _vm.item.path.split('/')[1]
                        ? 'a-c-master'
                        : 'a-c-blue'}}),(_vm.item.name == '待办事项' && _vm.todonum.totalNum)?_c('el-badge',{staticClass:"itembadge",attrs:{"value":_vm.todonum.totalNum,"max":99}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
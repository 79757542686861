const product_1 = {
	id: 1,
	img: 'https://s.alicdn.com/@sc01/kf/H56dc67dca64248c58fdfb1ffc721ed69N.jpg_480x480.jpg',
	content: 'Customize New decorative  Christmas   cute shaped cozy  teddy fur  gingerbread man  pillow cushion',
	tag: ['Chrismas', 'New'],
	minPrice: 3.2,
	maxPrice: 4.8,
	priceUnit: 'piece',
	minOrder: 200
}

const product_2 = {
	id: 2,
	img: 'https://s.alicdn.com/@sc01/kf/H0f64fa6144954359a0736650e4773d5fA.jpg_480x480.jpg',
	content: 'New arrival custom knit pillow ball round lambswool cushion style bedroom sofa decoration handmade knot ball pillow',
	tag: [],
	minPrice: 3.2,
	maxPrice: 0,
	priceUnit: 'piece',
	minOrder: 50
}

const product_3 = {
	id: 3,
	img: 'https://s.alicdn.com/@sc01/kf/H9ae838532747434eb69783fb3c5fb9c7F.jpg_480x480.jpg',
	content: 'Soft cozy decorative valentines faux rabbit bunny fur red pink white shaped heart pillow cushion',
	tag: [],
	minPrice: 2.56,
	maxPrice: 3.2,
	priceUnit: 'piece',
	minOrder: 50
}

const product_4 = {
	id: 4,
	img: 'https://s.alicdn.com/@sc01/kf/H9f59eb506fcf43fd871846c557c78a5ft.jpg_480x480.jpg',
	content: 'Wholesale holiday Xmas Decorative gift red ivory cozy teddy fur scarf hat snowman shaped pillow cushion pillow Christmas',
	tag: [],
	minPrice: 7.5,
	maxPrice: 8.8,
	priceUnit: 'piece',
	minOrder: 50
}

const product_5 = {
	id: 5,
	img: 'https://s.alicdn.com/@sc01/kf/Ha26a2b624c6942688ce907cd49bab69f9.jpg',
	content: 'New arrival seasonal cozy teddy fur snowflake plush decoration Christmas shaped pillow cushion',
	tag: [],
	minPrice: 4.83,
	maxPrice: 0,
	priceUnit: 'piece',
	minOrder: 50
}

const product_6 = {
	id: 6,
	img: 'https://s.alicdn.com/@sc01/kf/Hf22b299427794ed4a73e1de502abe672y.jpg_480x480.jpg',
	content: 'New Custom design  set of 2 wedding  luxury decorative shaped valentines  red love ivory  teddy  fur XO  pillow',
	tag: [],
	minPrice: 6.5,
	maxPrice: 9.85,
	priceUnit: 'piece',
	minOrder: 50
}

const product_7 = {
	id: 7,
	img: 'https://s.alicdn.com/@sc01/kf/H39d68feff53c4210b363f83fe4d28fbbO.jpg_480x480.jpg',
	content: 'Decorative LED sequin handwork rhinestone snowflake silver designs throw cushion cover embroidery pillow case Christmas',
	tag: [],
	minPrice: 4.65,
	maxPrice: 5.8,
	priceUnit: 'piece',
	minOrder: 50
}
const product_8 = {
	id: 8,
	img: 'https://s.alicdn.com/@sc01/kf/He7df9513ae4f41889dd6e2c8fa532740w.jpg_480x480.jpg',
	content: 'Hot sale faux linen base chain embroidery  48inch LED decoration Christmas Tree Skirt ornament',
	tag: [],
	minPrice: 9.8,
	maxPrice: 0,
	priceUnit: 'piece',
	minOrder: 50
}

const product_9 = {
	id: 9,
	img: 'https://s.alicdn.com/@sc01/kf/H4e09eb3c69174c36befe6314d22ba2f07.jpg',
	content: 'New outdoor indoor embroidery verbiage beach please anti-UV waterproof solution dye black  trim pillow cushion cover',
	tag: [],
	minPrice: 2.6,
	maxPrice: 4.5,
	priceUnit: 'piece',
	minOrder: 50
}
const product_10 = {
	id: 10,
	img: 'https://s.alicdn.com/@sc01/kf/Hb18053313e62494b847e2c6b1458e262J.jpg',
	content: 'Indoor outdoor beach coastal  print mermaid with jute trim  cushion pillow cover',
	tag: [],
	minPrice: 3.7,
	maxPrice: 5.25,
	priceUnit: 'piece',
	minOrder: 50
}


const state = {
	mainProduct: [{
		className: '',
		classImg: 'https://sc02.alicdn.com/kf/H7324796f15514acdaa9a2403a88e53267.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: '',
		classImg: 'https://sc02.alicdn.com/kf/H84ede0250e674fc6b5f1cd06ab6ab2eaD.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: '',
		classImg: 'https://sc02.alicdn.com/kf/H3066ffb641c340c883ded155aa22a283h.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: '',
		classImg: 'https://sc02.alicdn.com/kf/H1594034f2125434f8feb5dee82389ceam.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: '',
		classImg: 'https://sc02.alicdn.com/kf/H6a710dfe717846aba527006081f3abbfR.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}],
	allProduct: [{
		className: 'Seasonal',
		classImg: 'https://sc02.alicdn.com/kf/H7324796f15514acdaa9a2403a88e53267.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Outdoor',
		classImg: 'https://sc02.alicdn.com/kf/H84ede0250e674fc6b5f1cd06ab6ab2eaD.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Bedding',
		classImg: 'https://sc02.alicdn.com/kf/H3066ffb641c340c883ded155aa22a283h.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Pillow',
		classImg: 'https://sc02.alicdn.com/kf/H1594034f2125434f8feb5dee82389ceam.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Throw',
		classImg: 'https://sc02.alicdn.com/kf/H6a710dfe717846aba527006081f3abbfR.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Table Top',
		classImg: 'https://sc02.alicdn.com/kf/H6a710dfe717846aba527006081f3abbfR.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}, {
		className: 'Pet',
		classImg: 'https://sc02.alicdn.com/kf/H6a710dfe717846aba527006081f3abbfR.jpg',
		product: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
			product_8
		]
	}],
	topProduct: [product_1, product_2, product_3, product_4, product_5, product_6, product_7,
		product_8, product_9, product_10
	],
	chooseClass: '',
	chooseProducts:[]
}

const mutations = {
	setChooseClass: (state, chooseClass) => {
		state.chooseClass = chooseClass
	},
	addChooseProduct: (state, product) => {
		state.chooseProducts.push(product)
	},
	delChooseProduct: (state, product) => {
		let _index =-1;
			
		state.chooseProducts.map((item,index)=>{
			if(product.id == item.id){
				_index = index
			}
		})
		state.chooseProducts.splice(_index,1)
	}
}


const actions = {
	setChooseClass({
		commit
	}, data) {
		commit('setChooseClass', data)
	},
	addChooseProduct({
		commit
	}, data) {
		commit('addChooseProduct', data)
	},
	delChooseProduct({
		commit
	}, data) {
		commit('delChooseProduct', data)
	},
}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}

import Vue from "vue";
import Vuex from "vuex";
import product from './modules/product.js'
import user from './modules/user.js'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        product,
		user
    },
});

export default store;

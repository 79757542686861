import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/css/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import "@/assets/css/font-family.css"
import "@/assets/css/app.css"
import "@/assets/css/sub.css"
import '@/assets/iconfont/iconfont.css'
import "@/assets/css/common.css"
import "@/components/charts"



import {
	_post,
	_get,
	_delete,
	_put,
	_patch
} from '@/axios/func'
import Utils from "@/utils";
import Config from "@/config";

import App from '@/App'
import router from '@/router'
import store from '@/store'

import Breadcrumb from './components/layout/components/bread-crumb.vue'
Vue.component('bread-crumb', Breadcrumb)



import LeSelectLocalSearch from './views/components/form/le-select-local-search.vue'
Vue.component('le-select-local-search', LeSelectLocalSearch)

import LeSelectRemoteSearch from './views/components/form/le-select-remote-search.vue'
Vue.component('le-select-remote-search', LeSelectRemoteSearch)

import LeSelectNumRange from './views/components/form/le-select-num-range.vue'
Vue.component('le-select-num-range', LeSelectNumRange)

import LeDateRange from './views/components/form/le-date-range.vue'
Vue.component('le-date-range', LeDateRange)

import LeInputDistrict from './views/components/form/le-input-district.vue'
Vue.component('le-input-district', LeInputDistrict)

import LeInputDistrictChoose from './views/components/form/le-input-district-choose.vue'
Vue.component('le-input-district-choose', LeInputDistrictChoose)

import LeInputDistrictForm from './views/components/form/le-input-district-form.vue'
Vue.component('le-input-district-form', LeInputDistrictForm)

import LeInputDate from './views/components/form/le-input-date.vue'
Vue.component('le-input-date', LeInputDate)

import LeInput from './views/components/form/le-input.vue'
Vue.component('le-input', LeInput)

import LeInputSuffix from './views/components/form/le-input-suffix.vue'
Vue.component('le-input-suffix', LeInputSuffix)

import LeInputDisable from './views/components/form/le-input-disable.vue'
Vue.component('le-input-disable', LeInputDisable)

import LeInputNum from './views/components/form/le-input-num.vue'
Vue.component('le-input-num', LeInputNum)

import LeInputSlot from './views/components/form/le-input-slot.vue'
Vue.component('le-input-slot', LeInputSlot)

import LeInputIconSearch from './views/components/form/le-input-icon-search.vue'
Vue.component('le-input-icon-search', LeInputIconSearch)

import LeInputNumRange from './views/components/form/le-input-num-range.vue'
Vue.component('le-input-num-range', LeInputNumRange)

import LeInputPriceRange from './views/components/form/le-input-price-range.vue'
Vue.component('le-input-price-range', LeInputPriceRange)

import LeSearchForm from './views/components/form/le-search-form.vue'
Vue.component('le-search-form', LeSearchForm)

import LeSwitchBtn from './views/components/form/le-switch-btn.vue'
Vue.component('le-switch-btn', LeSwitchBtn)

import LeInputAddress from './views/components/form/le-input-address.vue'
Vue.component('le-input-address', LeInputAddress)

import LePagview from './views/components/table/le-pagview.vue'
Vue.component('le-pagview', LePagview)


import LeUploadFile from './views/components/form/le-upload-file.vue'
Vue.component('le-upload-file', LeUploadFile)

import LeInputAddressPoint from './views/components/form/le-input-address-point.vue'
Vue.component('le-input-address-point', LeInputAddressPoint)


import LeSwitch from './views/components/form/le-switch.vue'
Vue.component('le-switch', LeSwitch)

import LeUploadFileImgLY from './views/components/form/le-upload-file-img-ly.vue'
Vue.component('le-upload-file-img-ly', LeUploadFileImgLY)


import LeUploadFileImg from './views/components/form/le-upload-file-img.vue'
Vue.component('le-upload-file-img', LeUploadFileImg)

import LeServicePrivacy from './views/components/form/le-service-privacy.vue'
Vue.component('le-service-privacy', LeServicePrivacy)



Vue.use(ElementUI, {
	size: 'small'
});





import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$Axios = {
	_post,
	_get,
	_delete,
	_put,
	_patch
};
Vue.prototype.$Utils = Utils;
Vue.prototype.$Config = Config;



Vue.prototype.$eventBus = new Vue()


new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App),
}).$mount('#app');

<template>
	<div class="a-flex-rfsfs a-ptb-12" >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span class="a-mt-8">{{label}}</span>
		</div>
		<div class="a-flex-cfsfs a-ml-20">
			<el-input :class="large?'s-large-input':'s-nomarl-input'" placeholder="请选择省市区" readonly :value="myValue">
				 <template slot="suffix">
					 <div class="a-flex-rcc a-mt-8 a-plr-10" @click="myVisible=true">
						 <img src="../../../assets/icon/option-location.png" class="a-wh-16" />
						 <span class="a-c-master a-fs-14 a-ml-03">定位</span>
					 </div>
				 </template>
			</el-input>
			
			<el-input class="a-mt-8" v-model="myAddress" :class="large?'s-large-input':'s-nomarl-input'" placeholder="请输入详细地址"
				@input="handlerDetailChange">
			</el-input>
		</div>
		<le-address-choose :visible.sync="myVisible" @choose="handlerAddressChoose"></le-address-choose>
	</div>

</template>

<script>
	/**
	 * 省市区选择
	 */
	import {
		regionData,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	export default {
		props: {
			province: {
				required: false,
				type: String,
			},
			city: {
				required: false,
				type: String,
			},
			area: {
				required: false,
				type: String,
			},
			address: {
				required: false,
				type: String,
			},
			lat: {
				required: false,
			},
			lng: {
				required: false,
			},
			placeholder: {
				type: String,
				default: '请输入详细地址'
			},
			label: {
				type: String
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				provinceChange:false,
				cityChange:false,
				areaChange:false,
				
				myValue:'',
				
				myVisible:false,
				myProvince:'',
				myProvinceCode:'',
				myCity:'',
				myCityCode:'',
				myArea:'',
				myAreaCode:'',
				myAddress:'',
				myLat:'',
				myLng:'',
			}
		},
		computed:{
			codeChange(){
				return{
					provinceChange:this.provinceChange,
					cityChange:this.cityChange,
					areaChange:this.areaChange
				}
			}
		},
		watch:{
			province:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.provinceChange=true
					}
				}
			},
			city:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.cityChange=true
					}
				}
			},
			area:{
				handler(val,oldval){
					if(val!=oldval || !oldval){
						this.areaChange=true
					}
				}
			},
			codeChange:{
				handler(val){
					if(this.provinceChange && this.cityChange && this.areaChange){
						console.log('codeChange',this.province , this.city , this.area,CodeToText[this.province])
						if(this.province && this.city && this.area){
							this.myValue = CodeToText[this.province]+' / '+CodeToText[this.city]+' / '+CodeToText[this.area]
						}
						this.provinceChange = false
						this.cityChange = false
						this.areaChange = false
					}
				}
			},
			address:{
				handler(val,oldval){
					if(val!=oldval){
						this.myAddress=val
					}
				}
			}
		},
		mounted() {
			if(this.province && this.city && this.area){
				this.myValue = CodeToText[this.province]+' / '+CodeToText[this.city]+' / '+CodeToText[this.area]
			}
		},
		methods: {

			handlerDetailChange(value) {
				this.$emit("update:address",value)
			},
			handlerAddressChoose(value){
				this.myAddress = value.address
				this.myProvince = value.province;
				this.myCity = value.city;
				this.myArea = value.area;
				this.myProvinceCode = value.provinceCode
				this.myCityCode = value.cityCode;
				this.myAreaCode = value.areaCode;
				this.myLat = value.lat;
				this.myLng = value.lng;
				
				this.$emit("update:province",value.provinceCode)
				this.$emit("update:city",value.cityCode)
				this.$emit("update:area",value.areaCode)
				this.$emit("update:address",value.address)
				this.$emit("update:lat",value.lat)
				this.$emit("update:lng",value.lng)
			}
		}

	}
</script>


export default {
	//用户信息
	getUserInfo:'user/userInfo',//获取用户信息


    //登录
    mobileLogin:'user/mobile-login',//PC端手机号登录
    
	
	//菜单
	getUserMenu:'rbac/getUserMenu',//获取用户菜单 get

	getUserListByNickName:'user/getUserListByNickName',

	getList:"loushui/getList",

}

<template>
	<div class="a-flex-rfsc a-ptb-12"  >
		<div class="s-search-label a-flex-rfec " style="width: 82px;" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<div>{{label}}</div>
		</div>
		<div class="a-ml-20 a-flex-1" :class="large?'s-large-input':'s-nomarl-input'">
			<slot></slot>
		</div>
	</div>
	
</template>

<script>
	export default {
		props:{
			label:{
				type:String
			},
			placeholder:{
				type:String,
				default:'请输入'
			},
			notnull:{
				type:Boolean,
				default:false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large:{
				type:Boolean,
				required:false,
				default:false
			}
		},
		data() {
			return {
			}
		},
		methods:{
		}
	}
</script>

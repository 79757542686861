import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Utils from '@/utils';
import routes from './routes'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import {
	Message
} from 'element-ui'

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
	mode: 'hash',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: routes
});


router.beforeEach(async (to, from, next) => {
	//next();
	//NProgress.start();
	console.log('Utils.getToken()',Utils.getToken())
	if (Utils.getToken()) {
		next();
		//NProgress.done() // if current page is dashboard will not trigger afterEach hook, so manually handle it
	} else {
		
		if (to.path === '/login') {
			next()
		}else{
			next('/login');
		}
		 // ?redirect=${to.fullPath} 否则全部重定向到登录页
		//NProgress.done()
	}
	
});

router.afterEach((to, from) => {
	NProgress.done() // 结束Progress
});



export default router;

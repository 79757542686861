<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="a-flex-rfsc a-fs-14" style="cursor: pointer;">
            <i class="el-icon-success a-fs-16" :class="isRead?'a-c-blue':'a-c-second'" @click="handlerChange"></i>
            <span class="a-c-normal a-ml-5">同意</span>
            <span class="xy" @click="toBankXY('yhfw')">《用户服务协议》</span>
            <span class="a-c-normal">和</span>
            <span class="xy" @click="toBankXY('ysxy')">《隐私政策》</span>
        </div>
	</div>

</template>

<script>
export default {
    model: { 
        prop: 'isRead', 
        event: 'changeIsRead'  
    },
	props: {
		isRead: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {
            isReaded: false
		}
	},
    mounted () {
        this.isReaded = this.isRead
    },
	methods: {
		handlerChange() {
			// console.log('handlerChange',e)
            this.isReaded = !this.isReaded
			this.$emit('changeIsRead', this.isReaded)
		},
        toBankXY (type) {
            this.$router.push({
                path:'/businessCenter/bank-xy',
                query:{
                    type: type
                }
            })
        }
	}
}
</script>
<style lang="scss" scoped>
.xy{
    color: #3E4C94
}
.xy:hover{
    color: #007AFF
}
</style>

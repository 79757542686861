<template>
	<div class="a-mt-16 a-flex-cfsfs a-flex-1 aaaa">
		<slot name="headerLabel" :val="totalVal" ></slot>
		<div class="a-flex-1 a-w-100">
			<slot></slot>
		</div>
		
        <div class="a-flex-rsbc  a-w-100  a-mt-24">

            <slot name="footerLabel" :val="totalVal" ></slot>
            <div class="a-flex-rfec a-flex-1">
                <el-pagination  :page-size="10" :page-sizes="[10, 20, 50, 100]" layout="sizes, prev, pager, next" :total="total" background
                    @current-change='handleCurrentChange' @size-change='handleSizeChange'>
                </el-pagination>
            </div>
            
        </div>
	</div>
</template>

<script>
	export default {
		props: {
			pageParam:{
				type:Object,
				required:true,
				validator: (param) => {
					if(!param.url){
						console.log('pageview 参数缺少url')
						return false
					}
					if(!param.method){
						console.log('pageview 参数缺少method(post|get)')
						return false
					}
					if(!param.params){
						console.log('pageview 参数缺少params')
						return false
					}
					return true;
				}
			}
		},
		data() {
			return {
				total:30,
                totalVal: -1,// 表格数据左下角
				pageNum: 1,
				pageSize: 10,
				knowTotal:false,
				freshCtrl:-1,
			}
		},
		watch:{
			pageParam:{
				handler(val){
					//console.log('pageParam change')
					if(val && val.url && val.method && val.params){
						if(val.freshCtrl){
							if(this.freshCtrl != val.freshCtrl){
								this.freshCtrl = val.freshCtrl
								this.getTableData();
							}
						}else{
							this.getTableData();
						}
						
					}
				},
				deep:true,
				immediate: true
			}
		},
		
		methods: {
			getTableData() {
				let func = this.$Axios._post;
				if(this.pageParam.method.toLowerCase() == 'get'){
					func = this.$Axios._get;
				}
                console.log({
					...this.pageParam,
					params:{
						...this.pageParam.params,
						pageNum: this.pageNum,
						pageSize: this.pageSize
					}
				})
				func({
					...this.pageParam,
					params:{
						...this.pageParam.params,
						pageNum: this.pageNum,
						pageSize: this.pageSize
					}
				}).then(res => {
					console.log(res)
					this.$emit('setData',res.result.data.list,res.result.excelUrl)
					this.$emit('update:total',res.result.data.total)
					/**
					 * 有分页总数时
					 */
					if(res.result.data.total !=-1){
						this.total = res.result.data.total
                        this.totalVal = res.result.data.total
					}else{
						/**
						 * 无分页总数时
						 */
						if(res.result.data.length < this.pageSize){
							this.total = this.pageNum*this.pageSize-(this.pageSize-res.result.data.length)
							this.knowTotal = true;
						}else if(!this.knowTotal){
							let total = (this.pageNum+1)*this.pageSize
							if(total> this.total){
								this.total = total
							}
						}
					}
				})
			},
			
			// 行数切换
			handleSizeChange(value) {
				this.pageSize = value;
				this.pageNum = 1;
				this.getTableData();
			},
			// 页码切换
			handleCurrentChange(value) {
				this.pageNum = value;
				this.getTableData()
			},
		}
	}
</script>

<style>
	.aaaa:after{
        content: '';
        display: block;
        clear: both;
    }
</style>
